<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding">
            <b-row>
              <b-col md="8">
                <h4 class="mb-2">
                  Cajeros de <strong class="text-primary">{{ data.type ? data.type.label : '' }}</strong> en <strong class="text-primary">{{ data.country ? data.country.label : '' }}</strong>
                </h4>
              </b-col>

              <b-col md="4">
                <div
                  v-show="isChanged"
                  class="text-right"
                >
                  <span class="text-secondary small">Hay cambios por guardar</span>
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="20"
                    class="ml-50"
                  />
                </div>
              </b-col>
            </b-row>

            <div class="position-relative">
              <div
                v-if="!loading && isError"
                class="w-100 d-flex flex-column align-items-center justify-content-center"
                style="min-height: 200px"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="30"
                />
                <span>Ocurrio un error al cargar el json de los cajeros</span>
              </div>

              <div
                v-if="loading && !isError"
                class="w-100 d-flex flex-column align-items-center justify-content-center"
                style="min-height: 200px"
              >
                <b-spinner label="Spinning" />
                <span>Cargando...</span>
              </div>

              <div
                v-if="!loading && !isError && cashiers.length === 0"
                class="w-100 d-flex flex-column align-items-center justify-content-center"
                style="min-height: 200px"
              >
                <span>No se encontraron cajeros activos</span>
              </div>

              <b-row>
                <b-col
                  md="10"
                  offset-md="1"
                >
                  <draggable
                    v-show="!loading && !isError"
                    :list="cashiers"
                    :group="{ name: 'cashiers' }"
                    class="mb-1 row cursor-move"
                  >
                    <b-col
                      v-for="(item, index) in cashiers"
                      :key="index"
                      md="4"
                    >
                      <b-card
                        :title="item.name"
                        :img-src="item.image"
                        :img-alt="item.name"
                        img-top
                        tag="article"
                        class="card-cashier mb-2 border-1 border-lighten-3 border-secondary"
                      />
                    </b-col>
                  </draggable>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card>
          <div class="mb-2">
            <b-form-group
              label="Tipo de cajero"
              label-for="input-type"
            >
              <v-select
                v-model="data.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="types"
                :disabled="loadingSubmit"
                input-id="input-type"
                @input="fetchCashiers"
              />
            </b-form-group>

            <b-form-group
              label="País"
              label-for="input-country"
            >
              <v-select
                v-model="data.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :disabled="loadingSubmit"
                input-id="input-country"
                @input="fetchCashiers"
              />
            </b-form-group>

            <hr class="invoice-spacing">
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loadingSubmit"
            block
            @click="onSubmit"
          >
            <template v-if="loadingSubmit">
              <b-spinner
                class="mr-1"
                label="Small Spinning"
                small
              />
              Guardando...
            </template>
            <template v-else>
              Guardar y publicar
            </template>
          </b-button>
        </b-card>

        <b-card
          no-body
          class="text-secondary small p-0"
        >
          <p class="m-0 px-1 py-1">
            Diccionario:
          </p>

          <table class="table table-sm">
            <tr>
              <th class="px-1 text-secondary">
                País
              </th>
              <th class="px-1 text-secondary">
                Moneda
              </th>
            </tr>
            <tr
              v-for="(item, k) in dictionary"
              :key="k"
            >
              <td class="px-1 text-secondary">
                {{ item.country }}
              </td>
              <td class="px-1 text-secondary">
                {{ item.currency }}
              </td>
            </tr>
          </table>
          <p
            class="p-1 m-0 border-1 border-top"
            style="font-size: 90%;"
          >
            Todos los cajeros <strong>Dolar</strong> son de <strong>Ecuador</strong>
          </p>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  // BListGroupItem,
  BButton,
  BCard,
  BCardBody,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import { computed, onMounted, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { toast } from '@/libs/toastification-generic'
import {
  DEPOSIT,
  WITHDRAW,
} from '@core/constants'
import CashiersRequest from '@/@api/cashiers'
import useCurrencies from '../currencies/useCurrencies'
import useCountries from '../countries/useCountries'

export default {
  components: {
    draggable,
    // BListGroupItem,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BFormGroup,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { fetchCurrenciesSelector } = useCurrencies()
    const { fetchCountriesSelector } = useCountries()
    const countries = ref([])
    const currencies = ref([])
    const loadingSubmit = ref(false)
    const loading = ref(true)
    const isError = ref(false)
    const types = ref([
      { label: 'Depósito', value: DEPOSIT },
      { label: 'Retiro', value: WITHDRAW },
    ])
    const data = ref({
      type: null,
      currency: null,
      country: null,
    })
    const cashiers = ref([])
    const cashiersOrderOrigin = ref([])
    const dictionary = ref([])

    // Si no fuera porque este diccionario esta en el plugin, todo fuera mas fácil
    const dic = {
      CL: 'CLP',
      PE: 'PEN',
      BR: 'BRL',
      EC: 'USD', // Todos los cajeros USD son de Ecuador
      MX: 'MXN',
      GLOBAL: 'EUR',
    }

    const loadDictionary = () => {
      const newDic = {}

      let k = -1
      while (++k < countries.value.length) {
        const country = countries.value[k]
        let j = -1
        while (++j < currencies.value.length) {
          const currency = currencies.value[j]

          if (dic[country.value]) {
            newDic[country.value] = {
              country: country.label,
              currency: currency.value === dic[country.value] ? currency.label : newDic[country.value]?.currency ?? '',
            }
          }
        }
      }

      dictionary.value = Object.values(newDic)
    }

    const fetchCashiers = async () => {
      const { country, type } = data.value
      if (!country) {
        cashiers.value = []
        loading.value = false
        return []
      }

      loading.value = true
      isError.value = false
      try {
        const countryByCurrency = dic[country.value] ?? country.value

        const result = await CashiersRequest.order({
          params: {
            type: type.value,
            currency: countryByCurrency,
            // currency: currency.value,
            country: country.value,
          },
        })
        const json = result.data.data?.sort((a, b) => (a.orders[0]?.order ?? 0) - (b.orders[0]?.order ?? 0)) ?? []

        cashiers.value = json
        cashiersOrderOrigin.value = json.map(i => i.id)
        loading.value = false
        isError.value = false
        return json ?? []
      } catch (e) {
        console.log(e)
        toast({
          title: 'Ups! ocurrio un error al cargar',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        })

        cashiers.value = []
        loading.value = false
        isError.value = true
        return Promise.resolve([])
      }
    }

    const onSubmit = async () => {
      if (loading.value) return
      if (loadingSubmit.value) return

      loadingSubmit.value = true
      try {
        const { country, type } = data.value
        const form = {
          type: type.value,
          // currency_id: currency.value,
          country_id: country.value,
          cashiers: cashiers.value.map((c, i) => ({
            id: c.id,
            order: i,
            // currency_id: currency.id,
            country_id: country.id,
          })),
        }
        await CashiersRequest.applyOrder(form)

        cashiersOrderOrigin.value = cashiers.value.map(i => i.id)
        toast({
          title: 'Orden guardado con exito',
          icon: 'CheckIcon',
          variant: 'success',
        })
      } catch (error) {
        toast({
          title: 'Ups! ocurrio un error al guardar',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        })
        throw new Error(error)
      } finally {
        loadingSubmit.value = false
      }
    }

    const init = async () => {
      try {
        const resultCountries = await fetchCountriesSelector()
        const result = await fetchCurrenciesSelector()

        data.value = {
          type: types.value[0],
          currency: result[0],
          country: resultCountries[0],
        }
        currencies.value = result
        countries.value = resultCountries

        fetchCashiers()
        loadDictionary()
      } catch (error) {
        console.log(error)
      }
    }

    const isChanged = computed(() => {
      const order1 = JSON.stringify(cashiers.value.map(i => i.id))
      const order2 = JSON.stringify(cashiersOrderOrigin.value)

      return order1 !== order2
    })

    onMounted(init)

    return {
      loading,
      loadingSubmit,
      isError,
      data,
      fetchCashiers,
      onSubmit,
      currencies,
      countries,
      types,
      cashiers,
      isChanged,
      dictionary,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.card-cashier {
  max-width: 100%;
  height: calc(100% - 1.5rem);

  > img {
    aspect-ratio: 18/9;
    padding: 10px;
    background: white;
  }

  > .card-body {
    padding: 0.5rem;

    .card-title {
      margin-bottom: 1rem;
    }
  }
}
</style>
